import { getCustomerInfo } from '@api/public';

async function fetchData() {
  const custList = await getCustomerInfo({ status: 2, type: 'abbr' });
  const PROPS = new Set(['cust_abbr']);
  let indices = {};
  tableProperties.forEach(({ prop }, index) => {
    if (PROPS.has(prop)) indices[prop] = index;
  });
  return {
    [`tableProperties[${indices['cust_abbr']}].options`]: custList
  };
}
export const tableProperties = [
  {
    label: '年份',
    prop: 'year',
    itemType: 'date',
    type: 'year',
    valueFormat: 'yyyy',
    input: true,
    sortable: false
  },
  {
    label: '月份',
    prop: 'month',
    type: 'month',
    itemType: 'date',
    valueFormat: 'M',
    input: true,
    sortable: false
  },
  {
    label: '最终客户',
    otherProp: 'cust_id',
    prop: 'cust_abbr',
    itemType: 'select',
    options: [],
    filterable: true,
    input: true,
    sortable: false
  },
  {
    label: '货号数量',
    prop: '货号数量',
    secondaryHeader: [
      {
        label: '5000以下',
        prop: 'small_five_sum',
        itemType: 'input',
        input: false,
        sortable: false
      },
      {
        label: '5000-2万',
        prop: 'five_tow_sum',
        itemType: 'input',
        input: false,
        sortable: false
      },
      {
        label: '2万-5万',
        prop: 'tow_five_sum',
        itemType: 'input',
        input: false,
        sortable: false
      },
      {
        label: '5万-10万',
        prop: 'five_ten_sum',
        itemType: 'input',
        input: false,
        sortable: false
      },
      {
        label: '10万以上',
        prop: 'big_ten_sum',
        itemType: 'input',
        input: false,
        sortable: false
      }
    ]
  }
];
fetchData().then(data => {
  for (const key in data) {
    eval(key + '=data[key]');
  }
});
